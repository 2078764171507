import {errTips} from '@/util/error/error_tips';

const storeName = 'bigScreen'

// initial state
const state = {
    /** 今日数据*/
    todayData: {},

    /** 床位数据*/
    bunkData: {},

    /** 最近测量记录*/
    measureData: {},

    /** 订单数据*/
    orderData: {},

    /** 用户数据*/
    genderData: {},
    ageData: {},
    visitsUser7DData: {},

}

// getter
const getters = {}

//actions
const actions = {
    getTodayData({commit, getters}: any, payload: any) {
        const vm = payload.vm;
        commit('setState', {
            key: `todayData`,
            value: {"bed_total": 0,
                "check_in_total": 0,
                "online_bed_total": 0,
                "in_bed_total": 0,
                "today_measure_count": 0,
                "today_visit_count": 0,
                "today_place_order_count": 0}
        });

        return vm.$http.get(`/api/v1/monitor/today`).then((res: any) => {
            // console.log(res)
            commit('setState', {
                key: `todayData`,
                value: res.data
            });

            return res;
        }).catch((err: any) => {

        })
    },
    getBunkData({commit, getters}: any, payload: any) {
        const vm = payload.vm;
        return vm.$http.get(`/api/v1/monitor/beds`).then((res: any) => {
            // console.log(res)
            commit('setState', {
                key: `bunkData`,
                value: res.data.data
            });

            return res;
        }).catch((err: any) => {

        })
    },
    getMeasureData({commit, getters}: any, payload: any) {
        const vm = payload.vm;
        return vm.$http.get(`/api/v1/monitor/measure`).then((res: any) => {
            // console.log(res.data)
            commit('setState', {
                key: `measureData`,
                value: res.data
            });

            return res;
        }).catch((err: any) => {

        })
    },
    getOrderData({commit, getters}: any, payload: any) {
        const vm = payload.vm;
        return vm.$http.get(`/api/v1/monitor/order`).then((res: any) => {
            // console.log(res.data)
            commit('setState', {
                key: `orderData`,
                value: res.data
            });

            return res;
        }).catch((err: any) => {

        })
    },
    getUserData({commit, getters}: any, payload: any) {
        const vm = payload.vm;
        return vm.$http.get(`/api/v1/monitor/user`).then((res: any) => {

            commit('setState', {
                key: `genderData`,
                value: res.data.gender
            });
            commit('setState', {
                key: `ageData`,
                value: res.data.age
            });
            commit('setState', {
                key: `visitsUser7DData`,
                value: res.data.visits
            });

            return res;
        }).catch((err: any) => {
            // return errTips(vm, err).then(()=>{
            //     return Promise.reject(err);
            // });
        })
    },

}

//mutations
const mutations = {
    setState(state: any, payload: any) {
        state[payload.key] = payload.value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}