




import {
    Component,
    Vue,
    Prop,
    Watch,
} from 'vue-property-decorator';

@Component({
    name:'roll-digit',
})
export default class RollDigit extends Vue {
    @Prop({default: 0}) number!:number              // 目标数字
    @Prop({default: 1000}) duration!:number         // 持续时间
    @Prop({default: 100}) intervalTime!:number       // 间隔时长

    showNumber = 0;
    step = 0;

    @Watch('number')
    changeNumber() {
        this.buildRoll();
    }

    created(): void {
    }

    mounted() {
        this.buildRoll();
    }

    buildRoll() {
        this.initNumber().then(()=>{
            this.setRoll();
        }).then(() => {
            setTimeout(() => {
                this.showNumber = this.number;
            }, this.duration);
        });

    }

    async initNumber() {
        this.showNumber = 0
        this.step = Math.floor(this.number / Math.floor(this.duration/this.intervalTime));

        this.step = this.step  == 0 ? 1 : this.step;

        return this.step;
    }

    setRoll() {
        if(this.number > 0 && this.showNumber < this.number) {
            setTimeout(()=>{
                this.showNumber+=this.step;
                this.setRoll();
            },this.intervalTime)
        }else {
            this.showNumber = this.number;
        }
    }

};
